var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { format, cancel, render } from 'timeago.js';
/**
 * Convert input to a valid datetime string of <time> tag
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
 * @param input
 * @returns datetime string
 */
var toDateTime = function (input) {
    // let date: Date = new Date();
    // if (input instanceof Date) {
    //   date = input;
    //   //@ts-ignore
    // } else if (!isNaN(input) || /^\d+$/.test(input)) {
    //   //@ts-ignore
    //   date = new Date(parseInt(input));
    // } else {
    //   date = new Date(input);
    // }
    // try {
    //   return date.toISOString();
    // } catch (e) {
    //   console.error('invalid datetime');
    //   return '';
    // }
    return '' + (input instanceof Date ? input.getTime() : input);
};
var TimeAgo = /** @class */ (function (_super) {
    __extends(TimeAgo, _super);
    function TimeAgo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dom = null;
        return _this;
    }
    TimeAgo.prototype.componentDidMount = function () {
        // fixed #6 https://github.com/hustcc/timeago-react/issues/6
        // to reduce the file size.
        // const { locale } = this.props;
        // if (locale !== 'en' && locale !== 'zh_CN') {
        //   timeago.register(locale, require('timeago.js/locales/' + locale));
        // }
        // render it.
        this.renderTimeAgo();
    };
    TimeAgo.prototype.componentDidUpdate = function () {
        this.renderTimeAgo();
    };
    TimeAgo.prototype.renderTimeAgo = function () {
        var _a = this.props, live = _a.live, datetime = _a.datetime, locale = _a.locale, opts = _a.opts;
        // cancel all the interval
        cancel(this.dom);
        // if is live
        if (live !== false) {
            // live render
            this.dom.setAttribute('datetime', toDateTime(datetime));
            render(this.dom, locale, opts);
        }
    };
    // remove
    TimeAgo.prototype.componentWillUnmount = function () {
        cancel(this.dom);
    };
    // for render
    TimeAgo.prototype.render = function () {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _a = this.props, datetime = _a.datetime, live = _a.live, locale = _a.locale, opts = _a.opts, others = __rest(_a, ["datetime", "live", "locale", "opts"]);
        return (React.createElement("time", __assign({ ref: function (c) {
                _this.dom = c;
            } }, others), format(datetime, locale, opts)));
    };
    TimeAgo.defaultProps = {
        live: true,
        className: '',
    };
    return TimeAgo;
}(React.Component));
export default TimeAgo;
